import { useState } from 'react'
import { useFormSession } from './useFormSession'
import { useDispatch } from 'react-redux'
import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { setAgreementsConsignment, setAgreementsOffer, setAgreementsPurchase } from '../store/agreementsSlice'
import { useTranslation } from 'react-i18next'

export interface IUploadSignatureData {
  files?: {
    url: string
    name: string
  }[]
  agreement: 'purchase' | 'consignment' | 'offer'
  signature: string
}

export const useOfferAgreements = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { startCheckSession, stopCheckSession } = useFormSession()
  const [uploadSignatureLoading, setUploadSignatureLoading] = useState(false)
  const [deleteSignatureLoading, setDeleteSignatureLoading] = useState(false)

  const uploadSignature = async (data: IUploadSignatureData, isConsignedConvert = false) => {
    stopCheckSession()
    setUploadSignatureLoading(true)
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.upload}?lang=${i18n.language}`,
        data,
        isConsignedConvert,
      })

      if (resp && resp.data) {
        const url = resp.data.url

        if (data.agreement === 'consignment' && url) {
          dispatch(setAgreementsConsignment(url))
        }
        if (data.agreement === 'offer' && url) {
          dispatch(setAgreementsOffer(url))
        }
        if (data.agreement === 'purchase' && url) {
          dispatch(setAgreementsPurchase(url))
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setUploadSignatureLoading(false)
      startCheckSession()
    }
  }

  const deleteSignature = async (agreement: IUploadSignatureData['agreement']) => {
    stopCheckSession()
    setDeleteSignatureLoading(true)
    try {
      const resp = await callApi({
        method: 'DELETE',
        url: `${ApiRoutes.agreements}?agreement=${agreement}`,
      })
      if (resp && resp.data && resp.data.success) {
        if (agreement === 'consignment') {
          dispatch(setAgreementsConsignment(null))
        }
        if (agreement === 'offer') {
          dispatch(setAgreementsOffer(null))
        }
        if (agreement === 'purchase') {
          dispatch(setAgreementsPurchase(null))
        }
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setDeleteSignatureLoading(false)
      startCheckSession()
    }
  }

  return {
    deleteSignature,
    uploadSignature,
    deleteSignatureLoading,
    uploadSignatureLoading,
  }
}
