import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { callApi } from '../utils/callApi'
import { usePlaidLink } from 'react-plaid-link'
import { usePayments } from '../hooks/usePayments'
import { DefaultButton } from '../components/defaultButton'
import { capitalize } from 'lodash'
import { ApiRoutes } from '../utils/apiRoutes'
import { Loader } from '../components/loader'
import { IPlaidData } from '../types/additionalTypes'
import { SelectPlaidAccountModal } from '../components/modals/selectPlaidAccountModal'

export const AddPaymentData = () => {
  const location = useLocation()
  const { upload_token, link_token, isConsignedConvert } = queryString.parse(location.search)
  const { t } = useTranslation()
  const { open, ready } = usePlaidLink({ token: link_token ? (link_token as string) : null, onSuccess: onPlaidSuccess })
  const { getPlaidData } = usePayments()
  const [plaidOpened, setPlaidOpened] = useState(false)
  const [canceled, setCanceled] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [paymentInfoUploaded, setPaymentInfoUploaded] = useState(false)
  const [showPlaidAccountsModal, setShowPlaidAccountsModal] = useState(false)
  const [accountsList, setAccountsList] = useState<IPlaidData[] | null>(null)
  const [plaidToken, setPlaidToken] = useState('')

  const onSave = (data: any) => {
    if (data) {
      const isConvert = isConsignedConvert && isConsignedConvert === 'true' ? true : false
      callApi({
        method: 'POST',
        url: `${ApiRoutes.paymentQR}?token=${upload_token}`,
        isConsignedConvert: isConvert,
        data: {
          token: plaidToken,
          data: data,
        },
      })
        .then(() => {
          setLoading(false)
          setPaymentInfoUploaded(true)
        })
        .catch((e) => setError(e))
    }
  }

  function onPlaidSuccess(token: string, metadata: any) {
    const isConvert = isConsignedConvert && isConsignedConvert === 'true' ? true : false
    setLoading(true)
    getPlaidData(token, metadata.institution.name, isConvert).then((res) => {
      let firstValue = null
      let secondValue = null
      // console.log(res)
      if (!!res.numbers.ach.length) {
        const firstAccount = res.accounts?.find(({ account_id }: any) => account_id === res.numbers.ach[0].account_id)
        const secondAccount = res.numbers.ach[1]
          ? res.accounts?.find(({ account_id }: any) => account_id === res.numbers.ach[1].account_id)
          : null

        firstValue = {
          bankName: res.name,
          accountNumber: res.numbers.ach?.[0].account,
          routingNumber: res.numbers.ach?.[0].routing,
          accountType: capitalize(firstAccount.subtype),
          accountId: res.numbers.ach[0].account_id,
          clientName:
            firstAccount.owners &&
            firstAccount.owners[0] &&
            firstAccount.owners[0].names &&
            firstAccount.owners[0].names[0]
              ? firstAccount.owners[0].names[0]
              : '',
        }
        if (res.numbers.ach[1]) {
          secondValue = {
            bankName: res.name,
            accountNumber: res.numbers.ach?.[1].account,
            routingNumber: res.numbers.ach?.[1].routing,
            accountType: capitalize(secondAccount.subtype),
            accountId: res.numbers.ach[1].account_id,
            clientName:
              secondAccount.owners &&
              secondAccount.owners[0] &&
              secondAccount.owners[0].names &&
              secondAccount.owners[0].names[0]
                ? secondAccount.owners[0].names[0]
                : '',
          }
        }
      } else if (!!res.numbers.international.length) {
        const firstAccount = res.accounts?.find(
          ({ account_id }: any) => account_id === res.numbers.international?.[0].account_id
        )
        const secondAccount = res.numbers.international?.[1]
          ? res.accounts?.find(({ account_id }: any) => account_id === res.numbers.international?.[1].account_id)
          : null

        firstValue = {
          bankName: res.name,
          accountNumber: res.numbers.international?.[0].iban,
          swiftCode: res.numbers.international?.[0].bic,
          accountType: capitalize(firstAccount.subtype),
          accountId: res.numbers.international?.[0].account_id,
          clientName:
            firstAccount.owners &&
            firstAccount.owners[0] &&
            firstAccount.owners[0].names &&
            firstAccount.owners[0].names[0]
              ? firstAccount.owners[0].names[0]
              : '',
        }
        if (res.numbers.international?.[1]) {
          secondValue = {
            bankName: res.name,
            accountNumber: res.numbers.international?.[1].iban,
            swiftCode: res.numbers.international?.[1].bic,
            accountType: capitalize(secondAccount.subtype),
            accountId: res.numbers.international?.[1].account_id,
            clientName:
              secondAccount.owners &&
              secondAccount.owners[0] &&
              secondAccount.owners[0].names &&
              secondAccount.owners[0].names[0]
                ? secondAccount.owners[0].names[0]
                : '',
          }
        }
      }
      const values: IPlaidData[] = []
      if (firstValue) values.push(firstValue)
      if (secondValue) values.push(secondValue)
      setPlaidToken(token)
      setAccountsList(values)
      setShowPlaidAccountsModal(true)
    })
  }

  const plaidStart = () => open()

  const onEndSession = () => {
    setCanceled(true)
    callApi({
      method: 'DELETE',
      url: `/booking/images/flag?token=${upload_token}`,
      v1: true,
    })
      .then()
      .catch(console.error)
      .finally()
  }

  React.useEffect(() => {
    if (ready && !plaidOpened) {
      plaidStart()
      setPlaidOpened(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, plaidOpened])

  useEffect(() => {
    callApi({
      method: 'POST',
      url: `/booking/images/flag?token=${upload_token}`,
      v1: true,
    })
      .then()
      .catch(console.error)
  }, [upload_token])

  const getBody = () => {
    if (loading) return <Loader className='!mx-auto flex justify-center items-center' />
    if (error) {
      return <div className='text-red-400'>{error}</div>
    }
    if (paymentInfoUploaded) {
      return <div className='text-2xl font-medium text-zinc-800 text-center'>{t('Payment info uploaded')}</div>
    }

    return (
      <>
        <div className='text-2xl font-medium text-zinc-800 text-center'>
          {t(`If Plaid window won't open please click button below`)}
        </div>
        <DefaultButton title={t('Start')} classname='main_button !w-[200px]' onClick={plaidStart} />

        <button type='button' className='text_button !text-red-400 mt-8' onClick={onEndSession}>
          {t('Cancel session')}
        </button>
      </>
    )
  }

  return (
    <div className='flex flex-col justify-center items-center h-[80vh] px-3 gap-6'>
      {canceled ? (
        <div className='text-2xl font-medium text-zinc-800 text-center'>{t('Session Ended')}</div>
      ) : (
        getBody()
      )}

      <SelectPlaidAccountModal
        showed={showPlaidAccountsModal}
        accountsList={accountsList || []}
        closeModal={() => {
          setShowPlaidAccountsModal(false)
          setLoading(false)
        }}
        onDataSave={(data) => {
          setShowPlaidAccountsModal(false)
          onSave(data)
        }}
      />
    </div>
  )
}
